import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom/cjs/react-router-dom'

export default function Navbar(props) {
  return (
    <div className="container">
        {/* HEADER SECTION START */}
        {/* SECTION NAVBAR START */}
        <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src={`./logo512.png`} width="50" height="40" className="d-inline-block align-top" alt="TextPlay! logo" />{props.projectTitle}
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
            
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto mr-2">
                        <li className="nav-item active"><Link className={`nav-link text-${props.mode ==='light'?'dark':'light' }`} to="/">Home <span className="sr-only">(current)</span></Link></li>                
                        {/* <li className="nav-item dropdown">
                            <Link className={`nav-link dropdown-toggle text-${props.mode ==='light'?'dark':'light' }`} to="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Services
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link className="dropdown-item" to="/">Web Development</Link>
                                <Link className="dropdown-item" to="/">Front-end Development</Link>
                                <Link className="dropdown-item" to="/">Back-end Development</Link>
                                <Link className="dropdown-item" to="/">E-commerce Development</Link>
                            </div>
                        </li> */}
                        <li className="nav-item"><Link className={`nav-link text-${props.mode ==='light'?'dark':'light' }`} to="./blog">Blog</Link></li>
                        <li className="nav-item"><Link className={`nav-link text-${props.mode ==='light'?'dark':'light' }`} to="./about">{props.aboutText}</Link></li>
                        <li className="nav-item"><Link className={`nav-link text-${props.mode ==='light'?'dark':'light' }`} to="./contact">Contact Us</Link></li>
                    </ul>

                    <span className={`mr-2 text-${props.mode ==='light'?'dark':'light' }`}><i className="fa fa-sun-o" aria-hidden="true"></i></span>
                    <div className={`custom-control custom-switch text-${props.mode ==='light'?'dark':'light' }`}>
                        <input type="checkbox" onClick={props.toggleMode} className="custom-control-input" id="customSwitch1" />
                        <label className="custom-control-label text-right" htmlFor="customSwitch1"><i className="fa fa-moon-o" aria-hidden="true"></i></label>
                    </div>
                </div>
            </div>
        </nav>   
        {/* SECTION NAVBAR END */}
    </div>    
  )
}

Navbar.propTypes = {
    titleText: PropTypes.string.isRequired,
    aboutText: PropTypes.string
}

Navbar.defaultProps = {
    titleText: 'HirenReactJs',
    aboutText: 'About Us'
}
