import React, { useState } from 'react'

export default function TextForm(props) {

    // UPPERCASE TEXT
    const handleUpClick = ()=>{
        let newText = text.toUpperCase();
        setText(newText);
        props.showAlert("Converted to uppercase!", "success");
    }

    // LOWER TEXT
    const handleLowClick = ()=>{
        let newText = text.toLowerCase();
        setText(newText);
        props.showAlert("Converted to lowercase!", "success");
    }

    // CLEAR TEXT
    const handleClearClick = ()=>{
        let newText = '';
        setText(newText);
        props.showAlert("Text has been cleared!", "success");
    }

    // COPY TEXT
    const handleCopyClick = ()=>{
        let text = document.getElementById("myBox");
        text.select();
        navigator.clipboard.writeText(text.value);
        document.getSelection().removeAllRanges();
        props.showAlert("Text has been copied!", "success");
    }

    // REMOVE EXTRA SPACE TEXT
    const handleRemoveExtraSpaceClick = ()=>{
        let newText = text.split(/[ ]+/);
        setText(newText.join(" "));
        props.showAlert("Remove extra space on text!", "success");
    }

    const handleOnChange = (event)=>{
        setText(event.target.value);
    }

    const [text, setText] = useState('');
    // text = "new Text"; // Wrong way to change state
    // setText("new Text"); // Correct way to change state

    return (
        <>
            <div className="container">
                <h3 className={`mb-3 text-${props.mode ==='light'?'dark':'light' }`}>{props.headingText}</h3>
                <div className="form-group">
                    <textarea className="form-control" id="myBox" rows="6" value={text} onChange={handleOnChange}></textarea>
                </div>

                <button disabled={text.length===0} className="btn btn-primary btn-sm mx-1 my-1 {`text-${props.mode ==='light'?'dark':'light' }`}" onClick={handleUpClick}>Convert to uppercase</button>
                <button disabled={text.length===0} className="btn btn-primary btn-sm mx-1 my-1 {`text-${props.mode ==='light'?'dark':'light' }`}" onClick={handleLowClick}>Convert to lowercase</button>
                <button disabled={text.length===0} className="btn btn-primary btn-sm mx-1 my-1 {`text-${props.mode ==='light'?'dark':'light' }`}" onClick={handleClearClick}>Clear Text</button>
                <button disabled={text.length===0} className="btn btn-primary btn-sm mx-1 my-1 {`text-${props.mode ==='light'?'dark':'light' }`}" onClick={handleCopyClick}>Copy Text</button>
                <button disabled={text.length===0} className="btn btn-primary btn-sm mx-1 my-1 {`text-${props.mode ==='light'?'dark':'light' }`}" onClick={handleRemoveExtraSpaceClick}>Remove Extra Space</button>
            </div>

            <div className="container my-2">
                <h2 className={`text-${props.mode ==='light'?'dark':'light' }`}>Your Text Summary</h2>
                <p className={`text-${props.mode ==='light'?'dark':'light' }`}>{text.length>0 ?text.split(" ").filter((element)=>{return element.length!==0}).length:0} words, {text.length>0?text.length:0} characters</p>
                <p className={`text-${props.mode ==='light'?'dark':'light' }`}>{Math.round(0.008 * text.split(" ").filter((element)=>{return element.length!==0}).length)} Minutes read</p>

                
                <h2 className={`text-${props.mode ==='light'?'dark':'light' }`}>Preview Text</h2>
                <p className={`text-${props.mode ==='light'?'dark':'light' }`}>{text.length > 0 ? text : "Nothing to preview!"}</p>
            </div>        
        </>
    )
}
