import React, { useState } from 'react'

export default function About(props) {

    /* const [myStyle, setMyStyle] = useState({
        color: 'black',
        background: 'white',
    })

    const [btnText, setBtnText] = useState("Enable Dark Mode")

    const toggleStyle = ()=>{
        if(myStyle.color == 'black'){
            setMyStyle({
                color: 'white',
                background: 'black',
                boder: '1px solid white',
            })
            setBtnText("Enable Light Mode")
        }else{
            setMyStyle({
                color: 'black',
                background: 'white',
            })
            setBtnText("Enable Dark Mode")
        }
    } */

    let myStyle = {
        color: props.mode ==='dark'?'white':'black',
        background: props.mode ==='dark'?'black':'white',
    }

    return (
        <div className='container'>
            <h2>About Us</h2>
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingOne">
                    <h5 className="mb-0" style={{ color: props.mode ==='dark'?'white':'black' }}>
                    <i class="fa fa-line-chart" aria-hidden="true"></i>
                        <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <strong>Analyze Your Text</strong>
                        </button>
                    </h5>
                    </div>

                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body" style={myStyle}>
                        Explore the depths of your text with TextPlay! Our tool provides a swift and efficient way to analyze your text, allowing you to gain valuable insights and make informed decisions.
                    </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingTwo">
                    <h5 className="mb-0" style={myStyle}>
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                        <button className="btn btn-link collapsed" style={myStyle} data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <strong>Free To Use</strong>
                        </button>
                    </h5>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div className="card-body" style={myStyle}>
                    Enjoy the freedom to use TextPlay! without any cost! Our platform offers a range of text analysis features for free, empowering you to explore and enhance your text effortlessly.
                    </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingThree">
                    <h5 className="mb-0" style={myStyle}>
                    <i class="fa fa-bold" aria-hidden="true"></i>
                        <button className="btn btn-link collapsed" style={myStyle} data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <strong>Browser Compatible</strong>
                        </button>
                    </h5>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body" style={myStyle}>
                    Experience the freedom of browsing compatibility with our platform. TextPlay! ensures a seamless and consistent performance across different web browsers. Whether you prefer Chrome, Firefox, Safari, or any other browser, you can confidently use our services without any hindrance. Dive into text analysis without worrying about compatibility issues and enjoy a smooth user experience.
                    </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingFour">
                    <h5 className="mb-0" style={myStyle}>
                    <i class="fa fa-text-height" aria-hidden="true"></i>
                        <button className="btn btn-link collapsed" style={myStyle} data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <strong>Formatting Capabilities</strong>
                        </button>
                    </h5>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                    <div className="card-body" style={myStyle}>
                    Elevate your content presentation with TextPlay's advanced formatting capabilities. Our platform offers intuitive tools to refine the structure and appearance of your text. Whether it's organizing, styling, or aligning, TextPlay! provides a seamless experience for formatting your content. Enhance readability and visual appeal effortlessly with our versatile formatting features.
                    </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingFive">
                    <h5 className="mb-0" style={myStyle}>                        
                    <i class="fa fa-medium" aria-hidden="true"></i>
                        <button className="btn btn-link collapsed" style={myStyle} data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <strong>Modification Content</strong>
                        </button>
                    </h5>
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                    <div className="card-body" style={myStyle}>
                    Transform your text effortlessly with TextPlay's powerful modification features. Whether you need to tweak, edit, or enhance your content, our platform provides a user-friendly solution. Modify your text with precision, explore creative possibilities, and achieve the desired impact. Empower your writing with TextPlay's versatile text modification capabilities.
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}