import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function Blog() {
  return (
    <div className='container'>
            <section className="py-5">
                <div className="container">
                    <div className="row position-relative">
                        <div className="col-lg-10 mx-auto text-center position-relative">
                            <h1 className="mb-4">Blog</h1>
                            <div className="d-flex justify-content-center">
                                <p>If you’re interested in blog software, these related resources may help.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog-items p-3">
                <div className="container">
                    <div className="row g-4 filter-container overflow-hidden" data-isotope={{ layoutMode: "masonry" }} style={{ position: "relative", height: "1097.13px" }}>
            
                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: '0px', top: '0px' }}>
                            <div className="card bg-transparent">
                                <div className="overflow-hidden rounded-3">
                                    <img src={`./front-end/images/jpg/02.jpg`} className="card-img" alt="course image" />
                                    <div className="bg-overlay bg-dark opacity-4"></div>
                                    <div className="card-img-overlay d-flex align-items-start p-3">
                                        <Link to="/blog" className="badge bg-danger text-white">Student life</Link>
                                    </div>
                                </div>                               
            
                                <div className="card-body px-3">
                                    <h5 className="card-title"><Link to="/blog" className="text-dark font-weight-bold">Student Loan Survey: Many Owe $50K-plus</Link></h5>
                                    <p className="text-truncate-2">Affronting imprudence do he he everything. Offered chiefly farther of my no colonel shyness. Such on help ye some door if in. Laughter proposal laughing any son law consider. Needed except up piqued an.</p>
                                    <div className="d-flex justify-content-between">
                                        <h6 className="mb-0"><Link to="/blog" className="text-dark font-weight-bold">Frances Guerrero</Link></h6>
                                        <span className="small">30M Ago</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: '378px', top: '0px' }}>
                            <div className="card bg-light p-3">
                                <div className="card-body">
                                    <Link to="/blog" className="badge bg-success text-white mb-2">Research</Link>
                                    <h5 className="card-title">
                                        <Link to="/blog" className="text-dark font-weight-bold">How to make a college list</Link>
                                    </h5>
                                    <p>Prospective students should start broadly and then narrow their list down to colleges that best fit their needs, experts say. Yet remarkably appearance gets him his projection.</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-0"><Link to="/blog" className="text-dark font-weight-bold">Louis Crawford</Link></h6>
                                        <span className="small">12H Ago</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: '757px', top: '0px' }}>
                            <div className="card h-100 bg-transparent">
                                <div className="card-img-top rounded-3 overflow-hidden position-relative hover-overlay-top">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe className="embed-responsive-item" src="http://www.youtube.com/embed/9No-FiEInLA" allow="autoplay; encrypted-media" allowFullScreen="allowFullScreen"></iframe>
                                    </div>
                                </div>
                                
                                <div className="card-body px-3">
                                    <Link to="/blog" className="badge bg-purple text-white mb-2">Travel</Link>
                                    <h5 className="card-title">
                                        <Link to="/blog" className="text-dark font-weight-bold">Never underestimate the influence of Eduport</Link>
                                    </h5>
                                    <p className="text-truncate-2">Prospective students should start broadly and then narrow their list.</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-0"><Link to="/blog" className="text-dark font-weight-bold">Joan Wallace</Link></h6>
                                        <span className="small">5D Ago</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: '378px', top: '355px' }}>
                            <div className="card bg-transparent">
                                <div className="overflow-hidden rounded-3">
                                    <img src={`./front-end/images/jpg/03.jpg`} className="card-img-top" alt="course image" />
                                    <div className="bg-overlay bg-dark opacity-4"></div>
                                    <div className="card-img-overlay d-flex align-items-start p-3">
                                        <Link to="/blog" className="badge bg-warning text-dark">Lifestyle</Link>
                                    </div>
                                </div>
            
                                <div className="card-body px-3">
                                    <h5 className="card-title"><Link to="/blog" className="font-weight-bold text-dark">Covid-19 and the college experienced</Link></h5>
                                    <p>Rooms oh fully taken by worse do. Points afraid but may end law. Points afraid but may end law. </p>
                                    <div className="d-flex justify-content-between">
                                        <h6 className="mb-0"><Link to="/blog" className="font-weight-bold text-dark">Amanda Reed</Link></h6>
                                        <span className="small">July 21, 2021</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: '757px', top: '405px' }}>
                            <div className="card bg-dark p-3" style={{ backgroundImage: 'url(/front-end/images/jpg/05.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}>
                                <div className="card-body">
                                    <Link to="/blog" className="badge bg-warning text-dark mb-2">Lifestyle</Link>
                                    <h5 className="card-title text-primary-hover mb-1"><Link to="/blog" className="text-white font-weight-bold">Student Success</Link></h5>
                                    <span className="text-white font-weight-bold">Louis Crawford</span>
                                    <ul className="list-inline mb-0 mt-3">
                                        <li className="list-inline-item text-white me-3 mb-1 mb-sm-0">Aug 26, 2021</li>
                                        <li className="list-inline-item text-primary-hover me-3 mb-1 mb-xl-0"><Link to="/blog" className="text-white"><i className="fa fa-heart me-1"></i> 10</Link></li>
                                        <li className="list-inline-item text-primary-hover me-3 mb-1 mb-xl-0"><Link to="/blog" className="text-white"><i className="fa fa-comment me-1"></i> 5</Link></li>
                                        <li className="list-inline-item text-primary-hover me-3 mb-1 mb-xl-0"><Link to="/blog" className="text-white"><i className="fa fa-tag me-1"></i> Business</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: 0, top: 500 }}>
                            <div className="card bg-info p-3" style={{ background: 'url(/front-end/images/jpg/06.png) no-repeat center center', backgroundSize: 'cover' }}>
                                <div className="card-body">
                                    <h5 className="card-title text-primary-hover mb-1"><Link to="/blog" className="text-white">Choose your direction</Link></h5>
                                    <p className="text-white mb-0">https://textplay.einzigartige.in/</p>
                                </div>
                            </div>
                        </div>                        

                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: '0px', top: '635px' }}>
                            <div className="card h-100 bg-transparent">
                                <div className="card-img-top rounded overflow-hidden position-relative hover-overlay-top">
                                    <div className="ratio ratio-16x9">
                                        <iframe className="embed-responsive-item" src="https://player.vimeo.com/video/167434033?title=0&amp;byline=0&amp;portrait=0" width="350" height="200" allow="autoplay; encrypted-media" allowFullScreen="allowFullScreen"></iframe>
                                    </div>
                                </div>
                                <div className="card-body px-3">
                                    <Link to="/blog" className="badge bg-purple text-white mb-2">Technology</Link>
                                    <h5 className="card-title"><Link to="/blog" className="text-dark font-weight-bold">10 things you need to know about Eduport</Link></h5>
                                    <p className="text-truncate-2">Prospective students should start broadly and then narrow their list.</p>
                                    <div className="d-flex justify-content-between">
                                        <h6 className="mb-0"><Link to="/blog" className="text-dark font-weight-bold">Bryan Knight</Link></h6>
                                        <span className="small">20D Ago</span>
                                    </div>
                                </div>
                            </div>
                        </div>                        

                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: '757px', top: '630px' }}>
                            <div className="card bg-transparent">
                                <div className="overflow-hidden rounded-3">
                                    <img src={`./front-end/images/jpg/04.jpg`} className="card-img" alt="course image" />
                                    <div className="bg-overlay bg-dark opacity-4"></div>
                                    <div className="card-img-overlay d-flex align-items-start p-3">
                                        <Link to="/blog" className="badge bg-primary text-white">Sports</Link>
                                    </div>
                                </div>
            
                                <div className="card-body px-3">
                                    <h5 className="card-title"><Link to="/blog" className="text-dark font-weight-bold">The Olympics are over, now what?</Link></h5>
                                    <p>Rooms oh fully taken by worse do. Points afraid but may end law. Points afraid but may end law. </p>
                                    <div className="d-flex justify-content-between">
                                        <h6 className="mb-0"><Link to="/blog" className="font-weight-bold text-dark">Amanda Reed</Link></h6>
                                        <span className="small">Aug 31, 2021</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 grid-item" style={{ position: 'absolute', left: '378px', top: '790px' }}>
                            <div className="card bg-transparent">
                                <div className="overflow-hidden rounded-3">
                                    <img src={`./front-end/images/jpg/01.jpg`} className="card-img" alt="course image" />
                                    <div className="bg-overlay bg-dark opacity-4"></div>
                                    <div className="card-img-overlay d-flex align-items-start p-3">
                                        <Link to="/blog" className="badge bg-info text-white">Student story</Link>
                                    </div>
                                </div>
            
                                <div className="card-body px-3">
                                    <h5 className="card-title"><Link to="/blog" className="font-weight-bold text-dark">Campus Support for First-Year Students</Link></h5>
                                    <p className="text-truncate-2">Prospective students should start broadly and then narrow their list </p>
            
                                    <div className="d-flex justify-content-between">
                                        <h6 className="mb-0"><Link to="/blog" className="font-weight-bold text-dark">Lori Stevens</Link></h6>
                                        <span className="small">3M Ago</span>
                                    </div>
                                </div>
                            </div>
                        </div>           
                    </div>
                </div>
            </section>
    </div>
  )
}
